<template>
    <b-container fluid class="p-0 business">
        <b-container class="box-1 box">
            <b-row class="row-full">
                <b-col cols="12" md="6" lg="5">
                    <div class="project-item">
                        <div class="project-img">
                            <img
                                :src="
                                    require(`@/assets/images/mock/services/box-1.png`)
                                "
                                alt=""
                            />
                        </div>
                        <div class="project-name"></div>
                        <div class="project-line"></div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="7">
                    <div class="title">
                        {{ $t('comprehensiveFundraisingSolution') }}
                    </div>
                    <div class="description">
                        <p>
                            1. {{ $t('consultingOnBusiness') }} <br />
                            2. {{ $t('consultingOnCommunication') }} <br />

                            3. {{ $t('lookingForAAStrategicPartner') }}
                            <br />
                            4. {{ $t('comprehensiveReviews') }}
                            <br />
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid class="box box-2">
            <b-container class="box-content">
                <div class="title-box">{{ $t('ourSolution') }}</div>
                <div class="list">
                    <div
                        class="item"
                        v-for="solution in listSolution"
                        :key="`solution-${solution.title}`"
                    >
                        <div class="header-box">{{ solution.title }}</div>
                        <div class="body-box">
                            <p v-html="solution.content"></p>
                        </div>
                        <div class="icons-box">
                            <img
                                :src="
                                    require(`@/assets/images/mock/services/${solution.icons}.png`)
                                "
                                alt=""
                            />
                        </div>
                        <div class="line-bottom"></div>
                    </div>
                </div>
            </b-container>
        </b-container>
        <b-container class="box box-3" v-if="false">
            <!-- <a href="#"> GÓI ĐẦU TƯ PIF </a> -->
            <div class="warning">
                <div class="title">QUYỀN LỢI CỦA NHÀ ĐẦU TƯ:</div>
                <p>
                    - Nhận báo cáo hoạt động kinh doanh định kỳ theo tháng, quý,
                    năm.
                </p>
                <p>- Nhận thông tin tư vấn trước khi quyết định đầu tư.</p>
                <p>
                    - Doanh thu hàng tháng được chi trả trực tiếp về tài khoản
                    ngân hàng của nhà đầu tư vào ngày 5 hàng tháng.
                </p>
                <p>
                    - Tài sản đầu tư được quỹ PIF quản lý chuyên nghiệp, rõ
                    ràng, minh bạch,các báo cáo cập nhật trực tiếp trong tài
                    khoản của Nhà đầu tư.
                </p>
                <p>
                    - Hết kỳ hạn Nhà đầu tư được rút tổng doanh thu hoặc tiếp
                    tục gia hạn đầu tư.
                </p>
                <div class="title">TRÁCH NHIỆM CỦA NHÀ ĐẦU TƯ</div>
                <p>
                    - Bảo mật các thông tin cần thiết theo đúng cam kết giữa 3
                    bên Nhà đầu tư - Quỹ PIF - Doanh nghiệp.
                </p>
                <p>- Thực hiện đúng theo hợp đồng đã ký kết.</p>
            </div>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'Business',
    data() {
        return {};
    },
    computed: {
        listSolution() {
            return [
                {
                    title: this.$t('mainIssues'),
                    content: `${this.$t('lackOfVision')} <br /><br />
                    ${this.$t('lackOfCapital')}`,
                    icons: '1',
                },

                {
                    title: this.$t('ourSolution'),
                    content: `${this.$t('providingComprehensiveBusiness')} <br /><br />
                    ${this.$t('applicationOfModern')}`,
                    icons: '2',
                },

                {
                    title: this.$t('improvementIndex'),
                    content: `${this.$t('initialFunding')} <br /><br />
                    ${this.$t('profitAfterTax')}<br /><br />
                    ${this.$t('reduceWorking')}`,
                    icons: '3',
                },
                {
                    title: this.$t('sponsoredUnits'),
                    content: `${this.$t('startupCompany')}<br /><br />
                    ${this.$t('gamePlatform')}`,
                    icons: '4',
                },
            ]
        }
    }
};
</script>

<style lang="scss">
.business {
    .box-1 {
        padding: 30px 5%;
        box-shadow: 1px 1px 5px #0000005a;
        border-radius: 25px;
        margin-bottom: 50px;
        &:hover {
            .project-img {
                transition: all 1s;
                img {
                    transform: scale(1.2);
                    transition: all 1s;
                }
            }
        }
        .row-full {
            overflow: hidden;
            align-items: center;
            padding: 20px 0;
            .title-section {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
            }
            .title {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 29px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 15px;
                position: relative;
                padding-bottom: 15px;
                @media (max-width: 991px) {
                    font-size: 22px;
                }
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 10%;
                    height: 4px;
                    width: calc(70% - 10px);
                    background: #003189;
                }
                // &:after {
                // }
                &:before {
                    right: 20%;
                    left: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    border: 3px solid #003189;
                    bottom: 0;
                }
            }
            .description {
                p {
                    font-weight: 400;
                    font-size: 18px;
                    span {
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px 0;
                width: 100%;
                border-radius: 25px;
                max-width: 375px;
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 414px;
                }

                .project-img {
                    position: relative;
                    overflow: hidden;
                    border-radius: 25px;
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: auto;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 56.25%;
                        border-radius: 10px;
                        position: relative;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .content-project {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #00000076;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        h3 {
                            color: #ffff;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            color: #ffff;
                            font-size: 14px;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    .box-2 {
        background-color: #fbfdfd;
        padding: 50px 0;
        .box-content {
            min-height: 450px;
            box-shadow: 5px 0px 15px 0px rgba($color: #000000, $alpha: 0.3);
            border-radius: 25px 25px 5px 5px;
            overflow: hidden;
            padding: 0;
            @media (min-width: 1200px) {
                max-width: 1050px;
            }
            .title-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                font-family: 'Roboto', sans-serif;
                font-size: 30px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                @media (max-width: 991px) {
                    font-size: 25px;
                }
                @media (max-width: 767px) {
                    font-size: 20px;
                }
            }
            .list {
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;
                .item {
                    min-height: 475px;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    min-width: 250px;
                    flex: 1;
                    position: relative;
                    box-shadow: 5px 0px 15px 0px
                        rgba($color: #000000, $alpha: 0.3);
                    &:nth-child(1) {
                        z-index: 4;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #3370de 0%,
                                #3370de 100%
                            );
                        }
                    }
                    &:nth-child(2) {
                        z-index: 3;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #004cd6 0%,
                                #004cd6 100%
                            );
                        }
                    }
                    &:nth-child(3) {
                        z-index: 2;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #0039a0 0%,
                                #0039a0 100%
                            );
                        }
                    }
                    &:nth-child(4) {
                        z-index: 1;
                        .line-bottom,
                        .header-box {
                            background: linear-gradient(
                                to right,
                                #00266b 0%,
                                #00266b 100%
                            );
                        }
                    }
                    .header-box {
                        height: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-family: 'Roboto', sans-serif;
                        color: #fff;
                        font-size: 20px;
                        font-weight: 600;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 0px 10px;
                        @media (max-width: 991px) {
                            font-size: 18px;
                        }
                        @media (max-width: 767px) {
                            font-size: 17px;
                        }
                    }
                    .body-box {
                        padding: 20px 15px;
                        min-height: 220px;
                        p {
                            text-align: left;
                            text-align-last: left;
                            line-height: 130%;
                            font-weight: 500;
                            font-size: 17px;
                        }
                    }
                    .icons-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: auto;
                            margin: auto;
                            height: 70px;
                        }
                    }
                    .line-bottom {
                        width: 100%;
                        height: 10px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
    .box-3 {
        padding: 50px 0;
        @media (max-width: 991px) {
            max-width: calc(100% - 10px);
        }
        a {
            background: linear-gradient(172deg, #237fff 0%, #0930ff 100%);
            border: 2px solid #fff;
            text-decoration: none;
            transition: all 0.5s;
            margin: 20px auto;
            min-height: 50px;
            min-width: 130px;
            max-width: 300px;
            padding: 5px 10px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all cubic-bezier(0.215, 0.61, 0.355, 1);
            color: #fff;
            font-weight: 600;
            text-decoration: none;
            font-size: 18px;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            box-shadow: 1px 2px 10px 2px rgba($color: #000000, $alpha: 0.3);

            &:hover {
                transform: scale(1.05);
            }
        }
        .warning {
            max-width: 90%;
            margin: 40px auto;
            @media (max-width: 575px) {
                max-width: 100%;
            }
            .title {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 30px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 10px;
                @media (max-width: 991px) {
                    font-size: 25px;
                }
                @media (max-width: 767px) {
                    font-size: 19px;
                }
            }
            p {
                font-weight: 500;
                margin-bottom: 5px;
            }
        }
    }
}
</style>
